<template>
  <div class="echarts-box">
    <el-card class="box-card">
      <div
        :style="{ height: height }"
        class="chart"
        :id="echartsData.id"
        ref="demo"
      ></div>
    </el-card>
  </div>
</template>
<script>
import echarts from 'echarts';
export default {
  props: {
    height: {
      type: String,
      default: '300px',
    },
    echartsData: {
      type: Object,
      default: function () {
        return {
          x: [],
          Ydata: [],
          data: [],
          setOptionObj: {},
          color: '#0da7e9',
          title: '',
        };
      },
    },
    showData: {
      type: String,
    },
  },
  data() {
    return {
      char: null,
    };
  },
  mounted() {
    // console.log("有几个echarts图，mounted函数就会被执行几次");
    this.showEcharts();
    window.addEventListener('resize', this.changeSize);
  },
  beforeDestroy() {
    // console.log("有几个echarts图，beforeDestroy函数就会被执行几次");
    window.removeEventListener('resize', this.changeSize);
  },
  watch: {
    // 通过watch监听属性去监视props 中echartsData数据的变化
    // 当属性发生变化的时候，调用showEcharts方法重现渲染echarts图表
    echartsData: {
      handler() {
        this.showEcharts();
      },
      // 这里的deep是深度监听，因为我们传递过来的是一个对象
      deep: true,
    },
  },
  methods: {
    changeSize() {
      // console.log("这里有可能是undefined为啥还可以正常缩放echarts", this.chart);
      this.char && this.char.resize();
    },
    showEcharts() {
      // 获取dom节点，
      let demo = this.$refs.demo;
      // 初始化echarts
      this.char = echarts.init(demo);
      this.char.clear(); // 在渲染之前清空实例
      let option = {};
      // 如果无数据的话，将展示暂无数据
      if (this.echartsData.x && this.echartsData.x.length == 0) {
        option = {
          title: {
            text: '暂无数据',
            x: 'center',
            y: 'center',
            textStyle: {
              fontSize: 20,
              fontWeight: 'normal',
            },
          },
        };
      } else {
        option = {
          dataZoom: [
            {
              type: 'slider', // 设置为滑动条型式
              show: true, // 显示dataZoom组件
              start: 0, // 默认显示的起始位置为0
              end: 80, // 默认显示的结束位置为100
              handleSize: 1, // 滑动条的手柄大小
              handleStyle: {
                color: '#DCE2E8', // 滑动条的手柄颜色
              },
              xAxisIndex: [0], // 表示控制第一个x轴
              filterMode: 'filter', // 设置为filter模式，即数据超过范围时会被过滤掉
            },
          ],
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            top: '20%',
            containLabel: true,
          },
          color: this.echartsData.color,
          title: {
            text: this.echartsData.title,
            textStyle: {
              fontSize: 16,
              fontWeight: 900,
              borderLeft: 10,
            },
          },
          xAxis: {
            type: 'category',
            data: this.echartsData.x,
            // nameLocation: "start",
            interval: 0,
            fontSize: 12,
            formatter: function (value, index) {
              if (index >= 10) {
                return ''; // 超过12条数据的标签不显示
              } else {
                return value;
              }
            },
          },
          yAxis: {
            type: 'value',
          },
          series: [],
        };
        // 如果父组件中有 series 这个字段，我们渲染多条折线
        if (
          this.echartsData.series &&
          this.echartsData.series.data &&
          this.echartsData.series.data.length
        ) {
          let legendArr = [];
          for (let i = 0; i < this.echartsData.series.data.length; i++) {
            option.series.push(this.echartsData.series.data[i]);
            legendArr.push(this.echartsData.series.data[i].name);
          }
          // 同时默认设置设置 legend， 当然父组件是可以到单独设置的
          option.legend = {
            x: 'center',
            data: legendArr,
            // 这个字段控制形状 类型包括 circle，rect ，roundRect，triangle，diamond，pin，arrow，none
            icon: 'circle',
            itemWidth: 10, // 设置宽度
            itemHeight: 10, // 设置高度
            itemGap: 32, // 设置间距
          };
        } else {
          // 否者就是单条折线
          option.series.push({
            data: this.echartsData.data,
            type: 'line',
          });
        }
        // 使用对象合并的方式让父组件可以对配置项可以单独设置
        option = Object.assign(option, this.echartsData.setOptionObj);
      }
      this.char.setOption(option);
    },
  },
};
</script>

<style scoped>
.echarts-box {
  width: 100%;
  height: 100%;
  margin-bottom: 15px;
}
</style>
